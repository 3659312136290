import {AppConfig} from "./AppConfig";
import {buildVersion} from "../lib/buildVersion";

export const appConfig: AppConfig = {
    practicumfitApiUrl: "https://api.dev.practicumfit.com/api/",
    internfitApiUrl: "https://api.dev.internfit.com/api/",
    pinnedApiUrl: "http://web.dev.practicumfit.com:9000/api/",
    isDebug: true,
    env: "dev",
    maxFileSize: 42967040,
    stripe: {
        publishable: "pk_test_upV1Hjkk0tZQWb2cJvQGc9or",
        secret: "sk_test_F3NJ5mC2xQM1CVSHIZ6JmVBN"
    },
    buildVersion: buildVersion
}
