/* eslint-disable */

const awsconfig = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:92671de8-450b-4492-a7f0-3f6f365e220a",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_MGvjuZKsm",
    "aws_user_pools_web_client_id": "4tlv46936ipb15071sdhilk90h",
    "oauth": {},
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://y795vme53m.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsconfig;
